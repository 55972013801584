import styled from "styled-components";

export const AboutContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    background: #FDC830;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F37335, #FDC830);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F37335, #FDC830); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

`

export const ContainerHeadingHolder = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    margin-bottom: 30px;
`

export const ContainerHeading = styled.h1`
    word-spacing: 10px;
    text-align: center;
    font-size: 2rem;
    color: black;
    margin: 0px 20px;
    position: relative;
    font-weight: 700;

    @media (max-width: 500px) {
        font-size: 1.5rem;
    }
`

export const ContainerHeadingLine = styled.div`
    position: relative;
    display: inline-block;
    border-radius: 5px;
    top: -50%;
    width: 25%;
    height: 5px;
    background: #343a40;
`


export const AboutHeading = styled.div`
    font-size: 2rem;
    font-weight: 800;
`

export const AboutTextContainer = styled.div`
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    max-width: 800px;
    padding-left: 20px;
    font-weight: 400;
    padding-right: 20px;
    font-size: 1rem;
    text-align: center;

    @media (max-width: 900px) {
        font-size: 0.8rem;
        max-width: 600px;
        font-weight: 500;
    }
    
`

export const AboutTextItem = styled.div`
    margin-bottom: 25px;
    color: #212529;
`
