import React from 'react'
import { ProductTableContainer, ProductTableContainerHeadingHolder, ProductTableContainerHeading, ProductTableContainerHeadingLine, ProductTableColumn, ProductTableRow, ProductTableHeader, ProductTableBody, ProductTableHolder, ProductHeader } from './styles/styled.producttable'
import {TiTick} from "react-icons/ti"

function ProductInfo() {
  return (
    <ProductTableHolder id="sizes">
    <ProductTableContainerHeadingHolder>
        <ProductTableContainerHeadingLine />
        <ProductTableContainerHeading>Our Range</ProductTableContainerHeading>
        <ProductTableContainerHeadingLine />
    </ProductTableContainerHeadingHolder>
    <ProductTableContainer>
                <ProductTableBody>
                <ProductTableRow underline>
                    <ProductTableColumn>Product</ProductTableColumn>
                    <ProductTableColumn>750ml</ProductTableColumn>
                    <ProductTableColumn>2lt</ProductTableColumn>
                    <ProductTableColumn>5lt</ProductTableColumn>
                    <ProductTableColumn>25lt</ProductTableColumn>
                </ProductTableRow>
                <ProductTableRow>
                    <ProductTableColumn product>Dishwashing Liquid</ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                </ProductTableRow>
                <ProductTableRow>
                    <ProductTableColumn>Handy Kleen</ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                </ProductTableRow>
                <ProductTableRow>
                    <ProductTableColumn>Fabric Softener</ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                </ProductTableRow>
                <ProductTableRow>
                    <ProductTableColumn>Pine Gel</ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                </ProductTableRow>
                <ProductTableRow>
                    <ProductTableColumn>Laundry Bleach</ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                </ProductTableRow>
                <ProductTableRow>
                    <ProductTableColumn>Degreaser</ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                </ProductTableRow>
                <ProductTableRow>
                    <ProductTableColumn>Silicon</ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                </ProductTableRow>
                <ProductTableRow>
                    <ProductTableColumn>Car Wash and Wax</ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                    <ProductTableColumn><TiTick /></ProductTableColumn>
                </ProductTableRow>
                </ProductTableBody>
                
    </ProductTableContainer>
    </ProductTableHolder>
  )
}

export default ProductInfo