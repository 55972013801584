import React from 'react'
import { InfoContainer, InfoItemContainer, InfoImageContainer, InfoImage, InfoItemHeading, InfoItemSubHeading, InfoItemText } from './styles/styled.info'
import InfoImg from "./imgs/info.png";



function Info() {
  return (
    <InfoContainer>
        
            <InfoImageContainer>
                <InfoImage src={InfoImg} />
            </InfoImageContainer>
        
            <InfoItemContainer>
            <InfoItemHeading>Visit our stores for quality cleaning products at your doorstep.</InfoItemHeading>
            <InfoItemText>At Homecare Chemicals, we pride ourselves in making sure that the finest raw materials are sourced, in order to produce quality products that can be sold to our customers.</InfoItemText>
        </InfoItemContainer>
        
    </InfoContainer>
  )
}

export default Info