import styled from "styled-components";

export const NavbarInfoContainer = styled.nav`
    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;

    
`

export const NavbarInfoItemContainer = styled.div`
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    flex: 1;
`

export const NavbarInfoItem = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    text-align: center;
    margin-left: 20px;

    &:hover {
        color: red;
    }
`

export const NavbarInfoImage = styled.div`
    margin-right: 5px;
    margin-top: 5px;
`

export const NavbarInfoText = styled.p`
    font-size: 0.7rem;
`