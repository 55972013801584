import React from 'react'
import {ImageContainer, ImageContainerHeadingHolder, ImageContainerHeadingLine, ImageContainerHeading, ImageItemContainer, ImageItemHolder, ImageItem} from "./styles/styled.imagecontainer";
import Img1 from "./imgs/dishliquid.png";
import Img2 from "./imgs/washwax.png"
import Img3 from "./imgs/handykleen.png"
import Img4 from "./imgs/fabricsoftener.png"
import Img5 from "./imgs/powerpine.png"
import Img6 from "./imgs/dishliquid25lt.png"

function ImageComponent() {
  return (
    <ImageContainer id="products">
      <ImageContainerHeadingHolder>
        <ImageContainerHeadingLine />
        <ImageContainerHeading>Featured Products</ImageContainerHeading>
        <ImageContainerHeadingLine />
      </ImageContainerHeadingHolder>
      
      <ImageItemContainer>
        <ImageItemHolder><ImageItem src={Img1} /></ImageItemHolder>
        <ImageItemHolder><ImageItem src={Img2} /></ImageItemHolder>
        <ImageItemHolder><ImageItem src={Img3} /></ImageItemHolder>
        <ImageItemHolder><ImageItem src={Img4} /></ImageItemHolder>
        <ImageItemHolder><ImageItem src={Img5} /></ImageItemHolder>
        <ImageItemHolder><ImageItem src={Img6} /></ImageItemHolder>
      </ImageItemContainer>
    </ImageContainer>
  )
}

export default ImageComponent;