import React, {useState} from 'react'
import { NavContainer, NavLogo, NavInnerContainer, NavLeftContainer, NavRightIconContainer, NavRightContainer, NavLogoHolder, NavItemContainer, NavItem, NavExpandedContainer, NavExpandedItemContainer, NavExpandedItem, NavQuoteContainer, NavQuoteItem, NavLogoText} from "./styles/styles.navbar";
import {GrClose} from "react-icons/gr";
import {FiMenu} from "react-icons/fi";
import NavbarInfo from './NavbarInfo';
import LogoImg from "./imgs/logo.png"

function Navbar() {

const [showExpanded, setShowExpanded] = useState(false);

function handleScroll(item) {
    
  const violation = document.getElementById(item); 
  violation.scrollIntoView({ behavior: 'smooth' });

  setShowExpanded(false);
}

function handleEmailLink() {
  window.open("mailto:homecare.cleaningproducts@gmail.com", '_blank', 'noopener,noreferrer')
}


  return (
    <NavContainer>
              <NavbarInfo />
              <NavInnerContainer>
                <NavLeftContainer>
                <NavLogoHolder>
                  <NavLogo src={LogoImg} />
                  <NavLogoText>Homecare Cleaning</NavLogoText>
                </NavLogoHolder>
                </NavLeftContainer>

                <NavQuoteContainer>
                  <NavQuoteItem onClick={() => handleEmailLink()}>Get a quote</NavQuoteItem>
                </NavQuoteContainer>
                
                <NavRightContainer>
                  
                  <NavRightIconContainer
                    onClick={() => setShowExpanded(prev => !prev)}>
                      {!showExpanded ? <FiMenu size={32} /> : 
                      <GrClose size={32} />}
                  </NavRightIconContainer>
                  
                  <NavItemContainer>
                    <NavItem 
                    onClick={() => handleScroll("home")}>Home</NavItem>
                    <NavItem 
                    onClick={() => handleScroll("products")}>Products</NavItem>
                    <NavItem 
                    onClick={() => handleScroll("sizes")}>Sizes</NavItem>
                    <NavItem 
                    onClick={() => handleScroll("contact")}>Contact</NavItem>
                  </NavItemContainer>
                
                </NavRightContainer>

                
              </NavInnerContainer>
              <NavExpandedContainer showExpanded={showExpanded}>
                <NavExpandedItemContainer>
                    <NavExpandedItem 
                    onClick={() => handleScroll("home")}>Home</NavExpandedItem>
                    
                    <NavExpandedItem 
                    onClick={() => handleScroll("products")}>Products</NavExpandedItem>
                    
                    <NavExpandedItem 
                    onClick={() => handleScroll("sizes")}>Sizes</NavExpandedItem>
                    
                    <NavExpandedItem 
                    onClick={() => handleScroll("contact")}>Contact</NavExpandedItem>
                </NavExpandedItemContainer>
              </NavExpandedContainer>
            </NavContainer>
  )
}

export default Navbar