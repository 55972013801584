import React from 'react'
import { InfoContainer, InfoItemContainer, InfoImageContainer, ProductImage, InfoImage, InfoItemHeading, InfoItemSubHeading, InfoItemText, InfoItemButton } from './styles/styled.info'
import {MdPerson} from "react-icons/md";
import ProductImg from "./imgs/product.png"

function handleCallLink() {
  window.open("mailto:homecare.cleaningproducts@gmail.com", '_blank', 'noopener,noreferrer')
}

function Visit() {
  return (
    <InfoContainer>
        
        <InfoItemContainer visit>
            <InfoImageContainer>
              <ProductImage src={ProductImg} />
            </InfoImageContainer>
            <InfoItemSubHeading>Call us today for a quote on our cleaning materials and accessories. We pride ourselves in serving our customers, and ensuring that the highest quality standards are maintained.</InfoItemSubHeading>
            <InfoItemButton onClick={() => handleCallLink()}>Enquire Here</InfoItemButton>
        </InfoItemContainer>
        
    </InfoContainer>
  )
}

export default Visit;