import styled from "styled-components";

export const AppContainer = styled.div`
    height: 100%;
    width: 100%;
    background-color: #e5e3e4;
    position: relative;
    display: flex;
    flex-direction: column;
    color: black;
`