import { AppContainer } from "./styles/styled.appcontainer";
import NavbarInfo from "./NavbarInfo";
import Navbar from "./Navbar";
import Hero from "./Hero";
import {Carousel, CarouselItem} from "./Carousel"
import {CarouselImage} from "./styles/styled.carousel"
import Info from "./Info";
import ProductInfo from "./ProductInfo";
import Visit from "./Visit";
import Contact from "./Contact";
import Cards from "./Cards";
import Footer from "./Footer";

import ImageComponent from "./ImageComponent";
import About from "./About";

function App() {
  return (
    <AppContainer>
      <Navbar />
      <Hero />
      {/* <Cards /> */}
      
      {/* <Carousel>
        <CarouselItem>
        <CarouselImage src={Img1} />
        </CarouselItem>
        
        <CarouselItem>
          <CarouselImage src={Img2} />
        </CarouselItem>

        <CarouselItem>
          <CarouselImage src={Img3} />
        </CarouselItem>

        <CarouselItem>
          <CarouselImage src={Img4} />
        </CarouselItem>

      </Carousel> */}
      <ImageComponent />
      
      <ProductInfo />
      <About />
      <Visit visit />
      <Info />
      <Contact />
      <Footer />
    </AppContainer>
    
  );
}

export default App;
