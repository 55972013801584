import styled from "styled-components";


export const CarouselContainer = styled.div`
    overflow: hidden;
    border-radius: 5px;
    margin-top: 40px;

`

export const CarouselInner = styled.div`
    transform: translateX(-${props => (props.index * 100)}%);
    white-space: nowrap;
    transition: transform 0.3s;
    
`

export const CarouselStyleItem = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

export const CarouselImage = styled.img`

    border-radius: 10px;
    object-fit: fill;
    max-height: 500px;
    
`

export const CarouselTextHolder = styled.div`
    background-color: rgba(33, 37, 41, 0.8);
    width: 70%;
    height: 50%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    position: absolute;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`
export const CarouselText = styled.p`
    white-space: pre-wrap;      /* Webkit */    
    word-wrap: break-word;      /* IE */
    padding : 5px 20px;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2;
    text-align: center;
    color: #adb5bd;

    @media (max-width: 400px) {
        font-size: 0.9rem;
    }

    
`