import React from 'react'
import {ContactContainer, ContactItemHolder, ContactItemHeading, ContactItemText} from "./styles/styles.contact"

function Contact() {
    return(
  <ContactContainer id="contact">
    <ContactItemHolder>
        <ContactItemHeading>Contact Info</ContactItemHeading>
        <ContactItemText>Sagren Pillay</ContactItemText>
        <ContactItemText>076 651 5362</ContactItemText>
        <ContactItemText>homecare.cleaningproducts@gmail.com</ContactItemText>
    </ContactItemHolder>

    <ContactItemHolder>
        <ContactItemHeading>Port Shepstone</ContactItemHeading>
        <ContactItemText>082 050 2944</ContactItemText>
        <ContactItemText>Shop 21, Sheppy Mall, Aiken Street, Port Shepstone</ContactItemText>
    </ContactItemHolder>

    <ContactItemHolder>
        <ContactItemHeading>Margate</ContactItemHeading>
        <ContactItemText>079 076 2887</ContactItemText>
        <ContactItemText>Shop 8, Marine Drive, Margate</ContactItemText>
    </ContactItemHolder>

    <ContactItemHolder>
        <ContactItemHeading>Trading Hours</ContactItemHeading>
        <ContactItemText>Mon to Fri - 8am to 5pm</ContactItemText>
        <ContactItemText>Saturday - 8am to 1pm</ContactItemText>
        <ContactItemText>Sun & Public Hol - Closed</ContactItemText>
    </ContactItemHolder>

  </ContactContainer>)
}

export default Contact