import styled from "styled-components"
import HeroImg from "../imgs/hero-new2.jpg"

export const HeroContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    flex-direction: column;

    background: url(${HeroImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

`


export const Image = styled.img`
    
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin-top: 80px;
    z-index: 0;
    
`

export const VideoOverlay = styled.div`
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    
`

export const HeroSection = styled.div`
    
    position: absolute;
    width: 100%;
    z-index: 3;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    margin-top: 100px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgba(255, 255, 255, 1);    
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    @media (max-width: 550px) {
        h1 {
            margin: 0px 40px;
            font-size: 35px;
        }
    }


`

export const HeroImage = styled.img`
    width: 100%;
    align-self: center;
    max-width: 300px;
    justify-content: center;
    height: 100%;
    object-fit: contain;
`

export const HeroButton = styled.button`
    outline: 0;
    border: 3px solid #ff0418;
    text-transform: uppercase;
    margin-top: 30px;
    padding: 10px 40px;
    letter-spacing: 2px;
    border-radius: 5px;
    background-color: red;
    font-weight: 600;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: scale(0.9);
        cursor: pointer;
    }
`