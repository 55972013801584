import styled from "styled-components";

export const FooterContainer = styled.div`
    width: 100%;
    background-color: #343a40;
    color: white;
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    &:hover {
        cursor: pointer;
    }
`

export const FooterText = styled.div`
    margin: 0;
    padding: 0;
    font-size: 0.6rem;
    padding: 5px 20px;
    font-weight: 400;
`