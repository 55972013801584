import React from 'react'
import {CardContainer, CardItemContainer, CardHeading, CardItem, CardText} from "./styles/styled.cards"

function Cards() {
  return (
    <CardContainer>
      <CardHeading>Our  Range</CardHeading>
      <CardItemContainer>
        <CardItem color="#04b166">
          <CardText>Dishwashing Liquid</CardText>
        </CardItem>

        <CardItem color="#e5e3e4">
          <CardText>Handy Kleen</CardText>
        </CardItem>

        <CardItem color="#00a4d9">
          <CardText>Sta Soft</CardText>
        </CardItem>

        <CardItem color="#bdd630">
          <CardText>Pine Gel</CardText>
        </CardItem>
      </CardItemContainer>
      
    </CardContainer>
  )
}


export default Cards