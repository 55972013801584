import styled from "styled-components";

export const ProductTableHolder = styled.div`
    width: 100%;
    display: flex;
    padding: 20px;
    padding-bottom: 30px;
    flex-direction: column;
    justify-content: center;
    background: #2980B9;  /* fallback for old browsers */
background: -webkit-linear-gradient(to bottom right, #FFFFFF, #6DD5FA, #56c5ff);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to bottom, #FFFFFF, #FFFFFF, #56c5ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    
`

export const ProductHeader = styled.h1`
    word-spacing: 10px;
    text-align: center;
    margin: 20px 0px;
    color: #ef383a;
    font-weight: 800;
`

export const ProductTableContainerHeadingHolder = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    margin-bottom: 30px;
`

export const ProductTableContainerHeading = styled.h1`
    word-spacing: 10px;
    text-align: center;
    font-size: 2rem;
    color: black;
    margin: 0px 20px;
    position: relative;
    font-weight: 700;

    @media (max-width: 500px) {
        font-size: 1.5rem;
    }
`

export const ProductTableContainerHeadingLine = styled.div`
    position: relative;
    display: inline-block;
    border-radius: 5px;
    top: -50%;
    width: 25%;
    height: 5px;
    background: #343a40;
`


export const ProductTableContainer = styled.table`

    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    @media (max-width: 900px) {
        width: 100%;
    }
`

export const ProductTableBody = styled.tbody`
    width: 100%;
`

export const ProductTableRow = styled.tr`
    background-color: ${props => props.underline ? "#56c5ff" : ""};
    font-weight: ${props => props.underline ? "500" : "400"};
    font-size: 0.8rem;
    
    text-transform: ${props => props.underline ? "uppercase" : ""};;
`

export const ProductTableColumn = styled.td`
    padding: 10px;
    padding-left: 20px;
`

export const ProductTableHeader = styled.thead`
    text-align: center;
`