import styled from "styled-components";

export const NavContainer = styled.nav`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;

    z-index: 300;
    background-color: rgba(233, 236, 239, 1);
`

export const NavInnerContainer = styled.div`
    display: flex;
    width: 100%;
    height: 60px;
    padding-bottom: 10px;

`

export const NavExpandedContainer = styled.div`
    display: ${(props) => props.showExpanded ? "flex" : "none"};
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255, 0.9); 
    z-index: 200;
    overflow-x: hidden; 
    position: fixed;

`

export const NavLeftContainer = styled.div`
    display: flex;
    flex: 1;
    margin-left: 20px;
    justify-content: center;
`

export const NavRightContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
`

export const NavQuoteContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
`

export const NavRightIconContainer = styled.div`
    display: none;
    z-index: 300;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 800px) {
        display: flex;
        margin-right: 20px;
    }
`

export const NavLogoHolder = styled.div`
    width: 100%;
    display: flex;
    z-index: 300;
    align-items: center;
    justify-content: flex-start;
`

export const NavLogo = styled.img`
    width: 32px;
    height: 32px;
    z-index: 301;
`

export const NavLogoText = styled.p`
    margin-left: 5px;
    font-size: 0.8rem;

    @media (max-width: 600px) {
        display: none;
    }
`

export const NavItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 800px) {
        display: none;
    }
`
export const NavExpandedItemContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(146, 200, 38, 0.7);
    flex-direction: column;
    align-items: center;
    justify-content: center;

`

export const NavExpandedItem = styled.div`
    font-size: 1.8rem;
    text-transform: uppercase;
    margin-top: 20px;
    font-weight: 700;
    color: black;
    text-align: center;
    transition: all 0.3s ease-in-out;
    

    &:hover {
        cursor: pointer;
        color: white;
        font-weight: 700;
        transition: all 0.3s ease-in-out;
    }
`

export const NavItem = styled.div`
    margin-right: 30px;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;

    &:hover {
        cursor: pointer;
        color: #ef383a;
        font-weight: 700;
    }
`

export const NavQuoteItem = styled.button`
    height: 40px;
    width: 200px;
    background-color: #92c83f;
    color: white;
    border-radius: 5px;
    outline: none;
    border: none;
    font-weight: 800;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        opacity: 0.8;
    }
`