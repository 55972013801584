import React from 'react'
import { HeroContainer, HeroImage, Video, Image, VideoContainer, VideoOverlay, HeroSection, HeroMenu, HeroButton } from "./styles/styled.hero";

import LogoImg from "./imgs/updated.png";

function handleCallLink() {
  window.open("mailto:homecare.cleaningproducts@gmail.com", '_blank', 'noopener,noreferrer')
}

function Hero() {
  return (
    <HeroContainer id="home">

      <HeroSection>

        <HeroImage src={LogoImg} />
        {/* <HeroButton onClick={() => handleCallLink()}>Get in touch</HeroButton> */}
      </HeroSection>
      
      {/* <Image src={HeroImg} /> */}
      {/* <Video type="video/mp4" onCanPlay={videoSpeed} ref={videoRef} src={Vid} autoPlay loop muted  />    
             */}
    </HeroContainer>
  )
}

export default Hero