import styled from "styled-components";

export const CardContainer = styled.div`
    width: 100%;
    background-color: #bdd630;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
`

export const CardItemContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-self: center;
    justify-content: space-around;
    margin: 20px 10px;
`

export const CardHeading = styled.h1`
    word-spacing: 10px;
    text-align: center;
    margin: 10px;
    color: white;
    font-weight: 800;

`

export const CardItem = styled.div`
    border: 2px solid #d60709;
    display: flex;
    border-radius: 5px;
    flex: 50%;
    margin: 20px 50px;
    background-color: ${props => props.color ? props.color : "grey"};
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 10px 0px;
    transition: all 0.3s ease-in-out;
    
    &:hover {
        transition: all 0.3s ease-in-out;
        transform: scale(0.9);
    }
`

export const CardText = styled.p`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;

`