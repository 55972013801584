import styled from "styled-components";

export const ContactContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    padding: 20px;
    
    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`

export const ContactItemHolder = styled.div`
    display: flex;
    flex: 1;
    margin-right: 10px;
    flex-direction: column;

    @media (max-width: 800px) {
        margin-bottom: 10px;
    }
`

export const ContactItemHeading = styled.h1`
    font-weight: 700;
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;

    @media (max-width: 800px) {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`

export const ContactItemText = styled.p`
    text-align: center;
    font-size: 0.7rem;
    margin-bottom: 5px;
    
`