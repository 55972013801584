import React from 'react'
import { AboutContainer, AboutHeading, AboutTextContainer, AboutTextItem, ContainerHeading, ContainerHeadingHolder, ContainerHeadingLine } from './styles/styled.about'

function About() {
  return (
    <AboutContainer>
        <ContainerHeadingHolder>
            <ContainerHeadingLine />
            <ContainerHeading>About Us</ContainerHeading>
            <ContainerHeadingLine />
        </ContainerHeadingHolder>
        <AboutTextContainer>
            <AboutTextItem>Homecare Cleaning products and accessories was founded in 2009, which is owned and managed by
        Sagren and Lorraine Pillay.</AboutTextItem>
        <AboutTextItem>The core business of the company includes the manufacturing and distribution of a large variety of
        household chemicals. The business also offers its customer base a range of accessories that
        complements its customer purchases.</AboutTextItem>
        <AboutTextItem>With more than 12 years of experience, the company has succeeded in creating a one-stop shop which
        delivers high quality products at affordable prices to its valuable customers. Homecare cleaning
        products offers a holistic and value-added offering to various businesses and industries for own use or
        resell.</AboutTextItem>
        
        </AboutTextContainer>
    </AboutContainer>
  )
}

export default About