import styled from "styled-components";


export const InfoContainer = styled.div`
    width: 100%;
    justify-content: center;
    
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    display: flex;
    @media (max-width: 900px) {
        flex-direction: column;

    }
`

export const InfoItemContainer = styled.div`
    display: flex;
    flex: 1;
    padding: 40px 40px;
    flex-direction: column;
    background-color: ${props => props.visit ? "#ef383a" : "#92c83f"};
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    
    @media (max-width: 900px) {
        padding: 50px;
    }

`

export const InfoImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 50%;
    justify-content: center;

    @media (max-width: 900px) {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`

export const InfoImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;

    @media (max-width: 900px) {
        width: 100%;
    }
    
`

export const ProductImage = styled.img`

    max-width: 120px;
    height: auto;
    color: white;
    
    @media (max-width: 900px) {
        margin-bottom: 10px;
        width: 100px;
    }
`

export const InfoItemHeading = styled.div`
    font-weight: 700;
    font-size: 2.3rem;
    text-align: left;
    color: white;
`

export const InfoItemSubHeading = styled.h3`
    font-weight: 500;
    font-size: 1.3rem;
    margin: 20px 10px;
    margin-top: 30px;
    color: white;
    line-height: 1.6;
    max-width: 800px;
    word-spacing: 2px;
    text-align: center;

    @media (max-width: 900px) {
        font-size: 1rem;
    }
`

export const InfoItemText = styled.div`
    margin-top: 30px;
    text-align: left;
    font-size: 1rem;
    color: black;
    font-weight: 500;
    line-height: 1.6;


    @media (max-width: 900px) {
        margin-top: 30px;
    }
`

export const InfoItemButton = styled.button`
    outline: 0;
    border: 0;
    text-transform: uppercase;
    margin-top: 20px;
    padding: 10px 30px;
    letter-spacing: 2px;
    border-radius: 5px;
    font-weight: 600;
    color: black;
    background-color: white;
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: scale(0.9);
        cursor: pointer;
    }

`