import React from 'react'
import {NavbarInfoContainer, NavbarInfoImage, NavbarInfoItem, NavbarInfoItemContainer, NavbarInfoText} from "./styles/styled.navbarinfo"
import {BsFillTelephoneFill} from "react-icons/bs";
import {MdEmail} from "react-icons/md";

function handleCallLink() {
  window.open("tel:0766515362", '_blank', 'noopener,noreferrer')
}

function handleEmailLink() {
  window.open("mailto:info@homecarechemicals.com", '_blank', 'noopener,noreferrer')
}

function NavbarInfo() {
  return (
    <NavbarInfoContainer>
        <NavbarInfoItemContainer>
            <NavbarInfoItem onClick={() => handleCallLink()}>
                <NavbarInfoImage><BsFillTelephoneFill /></NavbarInfoImage>
                <NavbarInfoText>076 651 5362</NavbarInfoText>
            </NavbarInfoItem>

            <NavbarInfoItem onClick={() => handleEmailLink()}>
                <NavbarInfoImage><MdEmail /></NavbarInfoImage>
                <NavbarInfoText>homecare.cleaningproducts@gmail.com</NavbarInfoText>
            </NavbarInfoItem>
        </NavbarInfoItemContainer>
    </NavbarInfoContainer>
  )
}

export default NavbarInfo