import styled from "styled-components";

export const ImageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    justify-content: center;
    background-color: #bdd630;
    background: #bdd630;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #bdd630, #56ab2f);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #bdd630, #56ab2f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    justify-content: center;
`

export const ImageContainerHeadingHolder = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    margin-bottom: 20px;
`

export const ImageContainerHeading = styled.h1`
    word-spacing: 10px;
    text-align: center;
    font-size: 2rem;
    color: black;
    margin: 0px 20px;
    position: relative;
    font-weight: 700;

    @media (max-width: 500px) {
        font-size: 1.5rem;
    }
`

export const ImageContainerHeadingLine = styled.div`
    position: relative;
    display: inline-block;
    border-radius: 5px;
    top: -50%;
    width: 25%;
    height: 5px;
    background: #343a40;
`

export const ImageItemContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-wrap: wrap;
    max-width: 900px;
    justify-content: center;
`

export const ImageItemHolder = styled.div`
    display: flex;
    flex: 40%;
    margin: 20px 20px;
    justify-content: center;
    max-width: 500px;
`

export const ImageItem = styled.img`
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

`